<template>
  <div class="password-reset-registered">
    <div class="page-title">
      <base-page-title>{{
        $t('passwordResetRegistered.pageTitle')
      }}</base-page-title>
    </div>
    <div class="password-reset-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="check-mark">
      <base-check-mark />
    </div>
    <div class="message" data-test="message">
      {{ $t('passwordResetRegistered.message') }}
    </div>
    <div class="menu-button">
      <base-decision-button @click="pushToReservationMenu">{{
        $t('common.buttonMenu')
      }}</base-decision-button>
    </div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BaseCheckMark from '@/components/parts/atoms/BaseCheckMark.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import PreventBack from '@/components/mixins/PreventBack'

export default {
  name: 'PasswordResetRegistered',

  components: {
    BasePageTitle,
    ProgressBar,
    BaseCheckMark,
    BaseDecisionButton
  },

  mixins: [PreventBack],

  data() {
    return {
      progressBarData: {
        textList: [
          'passwordReset.enterEmailAddress',
          'passwordReset.userAuthentication',
          'passwordReset.setNewPassword',
          'passwordReset.finish'
        ],
        barWidthPc: 'password-resetting-screen',
        textOrangeNumber: 4
      }
    }
  },

  methods: {
    pushToReservationMenu() {
      this.$router.push({ name: 'ReservationMenu' })
    }
  }
}
</script>

<style lang="scss" scoped>
.password-reset-registered {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 70px;
  }
  > .password-reset-progress-bar {
    margin-top: 27px;
  }
  > .check-mark {
    margin-top: 39px;
  }
  > .message {
    margin-top: 50px;
    @include font-size(18);
    font-weight: bold;
  }
  > .menu-button {
    margin-top: 78px;
  }
}
@media (max-width: $global-media-width) {
  .password-reset-registered {
    > .check-mark {
      margin-top: 57px;
    }
    > .message {
      max-width: 410px;
      width: calc-percent(410px, 640px);
      margin: 50px auto 0px;
    }
    > .menu-button {
      margin-top: 85px;
    }
  }
}
</style>
